<template>
    <ODataLookup :data-object="dsClassificationCodesLookup" :whereClause="`'${IdPath}' LIKE IdPath + '%'`" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="150"></OColumn>
        <OColumn field="Title" width="300"></OColumn>
    </ODataLookup>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { ODataLookup } from 'o365-datalookup';

    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsClassificationCodesLookup = $getDataObjectById("dsAssets_ClassificationCodesLookup");

</script>